<template>
  <div @click="$emit('toggleMenu')" class="menu-toggle-btn row">
    <i v-if="!showMenu" class="fa-solid fa-bars"></i>
    <i v-else class="fa-solid fa-x"></i>
  </div>
</template>

<script>
export default {
  name: 'ToggleBtn',
  emits: ['toggleMenu'],
  props: {
    showMenu: { type: Boolean, default: false },
  },
  setup() {},
}
</script>

<style lang="scss" scoped>
.menu-toggle-btn {
  display: none !important;
  font-size: 1.5rem;
  background-color: var(--dark-10);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (orientation: portrait) or (max-width: 1100px) {
    display: flex !important;
  }

  i {
    color: white;
    transition: all 0.2s linear;

    &.fa-bars,
    &.fa-x {
      animation: fade 0.5s linear;
    }

    @keyframes fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}
</style>

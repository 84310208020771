<template>
  <div class="mobile__languages row">
    <div @click="$emit('updateLanguage', 'de')" class="link box__shadow row highlight">
      {{ t('nav-04') }}
    </div>

    <div @click="$emit('updateLanguage', 'en')" class="link box__shadow row highlight">
      {{ t('nav-05') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Languages',
  emits: ['updateLanguage'],
  setup() {},
}
</script>

<style lang="scss" scoped>
.mobile__languages {
  justify-content: space-between;
  margin-top: 10px;

  .link {
    width: 49%;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    border-radius: 15px;
  }
}
</style>

<template>
  <div class="mobile__menu column" :class="{ show: showMenu }">
    <Link
      @click="$emit('closeMenu')"
      text="nav-02"
      icon="fa-solid fa-user"
      :href="`https://app.sharedex.co/auth/login?language=${$store.state.language}`"
      :external="true"
    />
    <Link
      @click="$emit('closeMenu')"
      text="nav-03"
      icon="fa-solid fa-user-plus"
      :href="`https://app.sharedex.co/auth/register?language=${$store.state.language}`"
      :external="true"
    />

    <div class="line"></div>

    <Languages @updateLanguage="$emit('updateLanguage', $event)" />
  </div>
</template>

<script>
import { ref } from 'vue'

import Link from '../components/Link'
import Languages from './components/Languages'

export default {
  name: 'Mobile',
  emits: ['updateLanguage', 'closeMenu'],
  props: {
    showMenu: { type: Boolean, default: false },
  },
  components: { Link, Languages },
  setup() {
    const showLanguages = ref(false)

    return {
      showLanguages,
    }
  },
}
</script>

<style lang="scss" scoped>
.mobile__menu {
  height: 100%;
  position: fixed;
  top: 0;
  left: -600px;
  width: calc(100% - 60px);
  max-width: 340px;
  height: calc(100% - 120px);
  z-index: 5;
  padding: 100px 30px 20px 30px;
  background-color: white;
  transition: left 0.2s linear;
  overflow-y: scroll;

  &.show {
    left: 0;
    transition: left 0.2s linear;
  }

  @media (min-width: 1100px) {
    display: none !important;
  }
}
</style>

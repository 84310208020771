import { createApp } from 'vue'
import { MotionPlugin } from '@vueuse/motion'
import router from './router'
import store from './store'
import './registerServiceWorker'

import App from './App.vue'
import translation from './translation'

const app = createApp(App)

app.use(MotionPlugin)
app.use(store)
app.use(router)
app.use(translation)

app.mount('#app')

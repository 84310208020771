export default {
  'nav-01': {
    en: 'Pricing',
    de: 'Preise',
  },
  'nav-02': {
    en: 'Login',
    de: 'Login',
  },
  'nav-03': {
    en: 'Sign up',
    de: 'Anmelden',
  },
  'nav-04': {
    en: 'German',
    de: 'Deutsch',
  },
  'nav-05': {
    en: 'English',
    de: 'Englisch',
  },
  'nav-06': {
    en: 'Time Tracking',
    de: 'Zeiterfassung',
  },
  'nav-07': {
    en: 'Status Sharing',
    de: 'Status Sharing',
  },
  'nav-08': {
    en: 'Language',
    de: 'Sprache',
  },
  'nav-09': {
    en: 'Contact us',
    de: 'Kontakt',
  },
  'nav-10': {
    en: 'Authentication',
    de: 'Authentifizieren',
  },
  'nav-11': {
    en: 'Company',
    de: 'Unternehmen',
  },
  'nav-12': {
    en: 'Legal',
    de: 'Impressum',
  },
  'nav-13': {
    en: 'Privacy',
    de: 'Datenschutz',
  },
  'nav-14': {
    en: 'Terms',
    de: 'AGB',
  },
  'nav-15': {
    en: 'Individual statistics',
    de: 'Indiv. Statistiken',
  },
  'nav-16': {
    en: 'Features',
    de: 'Funktionen',
  },
  'nav-17': {
    en: 'Absence Tracking',
    de: 'Abwesenheiten',
  },
  'nav-18': {
    en: 'Reports',
    de: 'Reporting',
  },
  'nav-19': {
    en: 'E-Mail',
    de: 'E-Mail',
  },

  'feat-01': {
    en: 'Explore our time tracking solution.',
    de: 'Entdecke unsere Lösung zur Zeiterfassung.',
  },
  'feat-02': {
    en: 'Track absence like holidays easily.',
    de: 'Abwesenheiten wie Urlaub einfach erfassen.',
  },
  'feat-03': {
    en: 'All important information at a glance.',
    de: 'Alle wichtigen Infos auf einen Blick.',
  },
  'feat-04': {
    en: 'Share your status with a single click.',
    de: 'Teile deinen Status mit einem Klick.',
  },
}

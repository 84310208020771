export default {
  'banner-h1': {
    en: 'Time tracking made easy',
    de: 'Zeiterfassung leicht gemacht',
  },

  'banner-h2': {
    en: "It doesn't have to be complicated. Track your working hours with just one click and easily export your data for free as an Excel or PDF file.",
    de: 'Es muss nicht kompliziert sein. Erfasse deine Arbeitszeiten mit nur einem Klick und exportiere deine Daten ganz einfach und kostenlos als Excel- oder PDF-Datei',
  },

  'banner-h3': {
    en: 'Trusted by over 1,500 individuals',
    de: 'Über 1.500 Nutzer setzen schon auf uns',
  },

  'banner-action-01': {
    en: 'Get started for free',
    de: 'Jetzt kostenlos starten',
  },

  'banner-action-02': {
    en: 'Download',
    de: 'Herunterladen',
  },

  'power-btn-01': {
    en: 'Start your journey',
    de: 'Starte deine Reise',
  },

  'demo-headline': {
    en: 'Our core features at a glance',
    de: 'Unsere Kernfunktionen auf einen Blick',
  },

  Start: {
    en: 'Start',
    de: 'Start',
  },

  End: {
    en: 'End',
    de: 'Ende',
  },

  Break: {
    en: 'Break',
    de: 'Pause',
  },

  Total: {
    en: 'Total',
    de: 'Summe',
  },

  'workflow-headline': {
    en: 'The Sharedex workflow',
    de: 'Der Sharedex-Workflow',
  },

  'workflow-headline-01': {
    en: 'Track',
    de: 'Erfassen',
  },

  'workflow-body-01': {
    en: 'Work or break times can be added quickly and easily with just one click.',
    de: 'Arbeits- oder Pausenzeiten können mit nur einem Klick schnell und einfach hinzugefügt werden.',
  },

  'workflow-headline-02': {
    en: 'Modify (if necessary)',
    de: 'Bearbeiten (falls nötig)',
  },

  'workflow-body-02': {
    en: 'You can easily edit or delete your time tracking entries afterward.',
    de: 'Du kannst deine Zeiterfassungseinträge ganz einfach im Nachhinein bearbeiten oder löschen.',
  },

  'workflow-headline-03': {
    en: 'Add Absences',
    de: 'Abwesenheiten hinzufügen',
  },

  'workflow-body-03': {
    en: 'Easily add sick days or vacations, and have them count towards your tracked hours.',
    de: 'Füge Krankentage oder Urlaube problemlos hinzu, und lass sie zu deinen erfassten Stunden zählen.',
  },

  'workflow-headline-04': {
    en: 'Export',
    de: 'Exportieren',
  },

  'workflow-body-04': {
    en: 'You can export your data as an Excel or PDF file or send it automatically via email.',
    de: 'Deine Daten können als Excel- oder PDF-Datei exportiert oder automatisch per E-Mail versendet werden.',
  },

  'install-headline': {
    en: 'How to install Sharedex',
    de: 'Wie du Sharedex installierst',
  },
}

<template>
  <div v-if="showCookie" class="cookie__banner__container row">
    <div class="cookie__banner column">
      <p>
        {{ t('cookie_01') }}
        <router-link :to="`/${$store.state.language}/privacy`">{{ t('cookie_link') }}</router-link>
      </p>

      <div class="cookie__banner__btn__area row">
        <button class="reject__cookie" @click="clearCookies">{{ t('cookie_btn_reject') }}</button>
        <button class="accept__cookie" @click="acceptCookies">{{ t('cookie_btn_accept') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount } from 'vue'

export default {
  name: 'BaseCookieBanner',
  setup() {
    const showCookie = ref(true)
    const scrollY = ref(0)

    const acceptCookies = () => {
      showCookie.value = false
      localStorage.setItem('cookie_consent', true)
      localStorage.setItem('last_access', new Date().getTime())
    }

    const clearCookies = () => {
      document.cookie.split(';').forEach((cookie) => {
        const cookieName = cookie.split('=')[0].trim()
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
      })

      showCookie.value = false
    }

    onBeforeMount(() => {
      const cookieConsent = localStorage.getItem('cookie_consent') || false
      if (cookieConsent) showCookie.value = false
    })

    return {
      showCookie,
      scrollY,
      acceptCookies,
      clearCookies,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/variables.scss';

.cookie__banner__container {
  position: fixed;
  bottom: 10px;
  width: calc(100% - 20px);
  margin: 0 auto;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;

  @media (orientation: portrait) {
    justify-content: center;
    width: 100%;
    margin: 0;
  }

  .cookie__banner {
    background-color: var(--dark-10);
    padding: 20px;
    border-radius: 15px;
    width: 400px;
    max-width: calc(95% - 40px);

    p {
      margin: 0 0 20px 0;
      color: white !important;
      font-size: 0.9rem !important;

      a {
        color: white !important;
        text-decoration: underline !important;
      }
    }

    .cookie__banner__btn__area {
      display: flex;
      align-items: center;
      justify-content: center;

      .reject__cookie {
        border: none;
        background-color: transparent;
        color: white;
        text-decoration: underline;
        cursor: pointer;
        font-size: 0.9rem;
      }

      .accept__cookie {
        font-size: 0.9rem;
        height: 40px;
        padding: 0 25px;
        border-radius: 25px;
        margin-left: 50px;
        background-color: white;
        color: var(--dark-10);
        cursor: pointer;
        border: none;
      }
    }
  }
}
</style>

export default {
  'separator-pricing-01': {
    en: 'Find a suitable plan.',
    de: 'Entdecke unsere Pläne.',
  },
  'separator-pricing-02': {
    en: 'Check our pricing!',
    de: 'Sichere den besten Preis!',
  },
  'separator-pricing-03': {
    en: 'Pricing',
    de: 'Preise',
  },
  'separator-contact-01': {
    en: 'Any further questions?',
    de: 'Weitere Fragen?',
  },
  'separator-contact-02': {
    en: 'Get in touch with us!',
    de: 'Kontaktiere uns!',
  },
  'separator-contact-03': {
    en: 'Contact',
    de: 'Kontakt',
  },
  'separator-ready-01': {
    en: 'Ready to get started?',
    de: 'Bereit loszulegen?',
  },
  'separator-ready-02': {
    en: 'Create a free account!',
    de: 'Starte kostenlos!',
  },
  'separator-ready-03': {
    en: 'Start now',
    de: 'Starten',
  },
  'separator-schedule-01': {
    en: 'Learn more now.',
    de: 'Erfahre jetzt mehr.',
  },
  'separator-schedule-02': {
    en: 'Schedule a presentation!',
    de: 'Vereinbare einen Termin!',
  },
  'separator-schedule-03': {
    en: 'Schedule',
    de: 'Planen',
  },
}

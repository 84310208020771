export const clickOutsideElement = (query, callback) => {
  setTimeout(() => {
    const element = document.querySelector(`${query}`)

    if (!element) return

    const handleClick = (event) => {
      if (!element.contains(event.target)) {
        callback()
        document.removeEventListener('click', handleClick)
      }
    }

    document.addEventListener('click', handleClick)
  }, 100)
}

<template>
  <div class="desktop__menu row">
    <Languages @updateLanguage="$emit('updateLanguage', $event)" />

    <Link
      href="https://app.sharedex.co/auth/login"
      text="nav-03"
      :external="true"
      style="margin-right: -10px"
      :button="true"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { clickOutsideElement } from '@lib/helper.js'

import Link from '../components/Link'
import Languages from './components/Languages'

export default {
  name: 'Desktop',
  emits: ['updateLanguage'],
  components: { Link, Languages },
  setup() {
    const showFeatures = ref(false)

    const toggleFeatures = () => {
      showFeatures.value = !showFeatures.value

      if (showFeatures.value)
        clickOutsideElement('.feature__links', () => (showFeatures.value = false))
    }

    return {
      showFeatures,
      toggleFeatures,
    }
  },
}
</script>

<style lang="scss" scoped>
.desktop__menu {
  width: calc(100% - 250px);
  justify-content: end;

  @media (orientation: portrait) or (max-width: 1100px) {
    display: none !important;
  }
}
</style>

export default {
  cookie_01: {
    en: 'Sharedex uses cookies. By continuing to browse, you agree to our use of cookies.',
    de: 'Sharedex verwendet Cookies. Durch das weitere nutzen unserer Website stimmst du der Verwendung von Cookies zu.',
  },

  cookie_link: {
    en: 'Learn more',
    de: 'Mehr erfahren',
  },

  cookie_btn_reject: {
    en: 'Reject all',
    de: 'Alle ablehnen',
  },

  cookie_btn_accept: {
    en: 'Accept all',
    de: 'Alle akzeptieren',
  },
}
